
export default {
    defaultFontFamily: 'Arial,Open Sans,sans-serif',
    h1: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '2em',
    },
    h2: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1.7em',
    },
    h3: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1.4em',
    },
    h4: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1.05em',
    },
    h5: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.05em',
    },
    intro: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1.1em',
    },
    body: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.1em',
    },
    smallBody: {
        fontStyle: 'normal',
        fontWeight: 'norml',
        fontSize: '1em',
    },
    link: {

    },
    buttonText: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1.05em',
        lineHeight: '1.5em'
    },
    buttonIcon:{
        height: '1.5em',
        width: '2em'
    },
    footerMainLink: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1em',
        textDecoration: 'none',
        "&:hover": {
          textDecoration: 'underline',
        }
    },
    footerGroupHeader: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1em',
    },
    footerGroupLink: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1em',
        textDecoration: 'none',
        "&:hover": {
          textDecoration: 'underline',
        }
    },
  }