import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
  }
}))

const Footer = (props) => {

  const classes = useStyles();

  return (
    <footer className={classes.root}>
    </footer>
  )
}

export default Footer
