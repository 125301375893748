import PropTypes from 'prop-types'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import CssBaseline from '@material-ui/core/CssBaseline'

const useStyles = makeStyles(theme => ({
  root: {
  }
}))

const Header = ({ siteTitle }) => {
  const classes = useStyles()

  return (
    <header className={classes.root}>
      <CssBaseline />
      <Box className= {classes.root}>
        <Typography variant="h1" component="h2">Curriculum Vitae</Typography>
        <Typography variant="h1" component="h1">Graham Arthur Daw</Typography>
      </Box>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
