export default {
    "base": {
        "white": "#FFFFFF",
        "grey": "#4D4D4D",
        "black": "#000000"
    },
    "purple": {
        "0": "#40488D",
        "1": "#323976",
        "2": "#2B326B",
        "3": "#262C60"
    },
    "blue": {
        "0": "#00B4CD",
        "1": "#009EB4",
        "2": "#018A9D",
        "3": "#007586"
    },
    "pink": {
        "0": "#BC1077",
        "1": "#A60B68",
        "2": "#99095F",
        "3": "#840852"
    },
    "orange": { 
        "0": "#F36633" 
    },
    "grey": { 
        "0": "#4D4D4D",
        "1": "#EDEFF0"
    }
}