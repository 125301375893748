import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"

import { ThemeProvider } from '@material-ui/styles'
import theme from './theme/theme'

//import SEO from './SEO'
import Header from './Header'
import Footer from './Footer'
import './layout.css'


import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({

}))


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteDataQuery {
      site {
        siteMetadata {
          title
        }

      }
    }
  `)

  const classes = useStyles();

  return (
    <ThemeProvider theme={{
      ...theme
    }}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer/>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout