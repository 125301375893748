import { createMuiTheme } from '@material-ui/core/styles'

import typography from './typography'
import colors from './colors'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.base.grey,
      contrastText: colors.base.white
    }
  },
  typography: {
    fontFamily: typography.defaultFontFamily,
    fontStyle: 'normal',
    htmlFontSize: 10,

    h1: typography.h1,
    h2: typography.h2,
    h3: typography.h3,
    h4: typography.h4,
    h5: typography.h5,
    subtitle1: typography.intro,
    subtitle2: typography.body,
    body1: typography.body,
    body2: typography.smallBody,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: { 
          maxWidth: '1380px',
          margin: '0 auto',
          boxShadow: '0px 0px 60px 5px rgba(0,0,0,0.3)'
        }
      }
    }
  }
})

export default theme